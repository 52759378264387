<template>
  <div id="SupplierRegistrationScreen">
    <v-container>
      <v-card>
        <v-app-bar dense color="transparent">
          <v-toolbar-title>
            <h5>Daftar Pengguna</h5>
          </v-toolbar-title>
        </v-app-bar>

        <v-card-text class="pa-0">
          <template>
            <v-data-table
              :headers="headers"
              :items="supplierList"
              mobile-breakpoint="0"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td
                    class="detailLink"
                    @click="getDetail(props.item.pending_task_id)"
                  >
                    {{ props.item.company_name }}
                  </td>
                  <td>{{ props.item.company_address }}</td>
                  <td>{{ props.item.responsible_name }}</td>
                  <td>{{ props.item.phone_number }}</td>
                  <td>{{ props.item.email }}</td>
                  <td>{{ props.item.requested_date }}</td>
                </tr>
              </template>
            </v-data-table>
          </template>
        </v-card-text>
      </v-card>

      <v-dialog v-model="dialog" max-width="1200">
        <v-card class="mx-auto" outlined>
          <v-app-bar dense color="transparent">
            <v-toolbar-title>
              <h5>Detail Pengguna</h5>
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text class="pa-0">
            <div class="pa-5">
              <v-row>
                <v-col>
                  <v-row>
                    <v-col class="col-4">
                      Nama Usaha/ Perusahaan
                    </v-col>
                    <v-col class="col-8">
                      :&ensp; {{ dataDetail.company_name }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="col-4">
                      Cakupan Area Wilayah Kerja
                    </v-col>
                    <v-col class="col-8">
                      :&ensp; {{ dataDetail.working_area }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="col-4">
                      Status Perusahaan
                    </v-col>
                    <v-col class="col-8">
                      :&ensp; {{ dataDetail.company_status }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="col-4">
                      KTP
                    </v-col>
                    <v-col class="col-8">
                      :&ensp; {{ dataDetail.id_card_number }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="col-4">
                      Kode Pos
                    </v-col>
                    <v-col class="col-8">
                      :&ensp; {{ dataDetail.postal_code }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="col-4">
                      Alamat
                    </v-col>
                    <v-col class="col-8">
                      :&ensp; {{ dataDetail.company_address }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="col-4">
                      Kelurahan
                    </v-col>
                    <v-col class="col-6 align-self-center">
                      <v-autocomplete
                        v-model="villageSelect"
                        :items="villageList"
                        item-text="village_name"
                        item-value="postal_code_id"
                        placeholder="Masukkan Nama Kelurahan"
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="col-4">
                      Kode Supplier
                    </v-col>
                    <v-col class="col-6 align-self-center">
                      <v-text-field
                        dense
                        v-model="supplierCode"
                        placeholder="Masukkan Kode Supplier"
                        :rules="supplierCodeRules"
                        outlined
                        clearable
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="col-12">
                      <div>Foto KTP</div>
                      <div class="mt-5">
                        <expandable-image
                          :src="dataDetail.url_attachment_ktp"
                          width="500px"
                          close-on-background-click
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-card shaped class="pa-5">
                    <h3>Penanggung Jawab</h3>
                    <v-row>
                      <v-col class="col-4">
                        Nama
                      </v-col>
                      <v-col class="col-8">
                        :&ensp; {{ dataDetail.responsible_name }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="col-4">
                        Nomor Handphone
                      </v-col>
                      <v-col class="col-8">
                        :&ensp; {{ dataDetail.phone_number }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="col-4">
                        Email
                      </v-col>
                      <v-col class="col-8">
                        :&ensp; {{ dataDetail.email }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="col-4">
                        NPWP
                      </v-col>
                      <v-col class="col-8">
                        :&ensp; {{ dataDetail.npwp }}
                      </v-col>
                    </v-row>
                    <v-row class="mt-5">
                      <v-col class="col-12">
                        <div>Foto NPWP</div>
                        <div class="mt-5">
                          <expandable-image
                            :src="dataDetail.url_attachment_npwp"
                            width="500px"
                            close-on-background-click
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-if="flagReject">
                <v-col class="col-2">
                  Alasan
                </v-col>
                <v-col class="col-10 align-self-center">
                  <v-textarea
                    v-model="reasonMessage"
                    outlined
                    counter
                    no-resize
                    clearable
                  ></v-textarea>
                </v-col>
              </v-row>
            </div>

            <v-divider></v-divider>

            <v-card-actions>
              <v-container>
                <div class="d-flex justify-end mt-3">
                  <v-btn
                    color="error"
                    :loading="registrationReject"
                    :disabled="registrationReject"
                    @click="reject"
                    class="mr-5"
                    >Tolak</v-btn
                  >
                  <v-btn
                    color="success"
                    :loading="registrationApprove"
                    :disabled="registrationApprove"
                    @click="approve"
                    class="mr-5"
                    >Terima</v-btn
                  >
                </div>
              </v-container>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import globalMixin from "@/mixins/globalMixin.js";
import RegistrationService from "@/services/RegistrationService";

export default {
  mixins: [globalMixin],

  data() {
    return {
      dialog: false,
      dataDetail: {},
      supplierList: [],
      headers: [
        { text: "Nama Usaha", sortable: false },
        { text: "Alamat", sortable: false },
        { text: "Nama Penanggung Jawab", sortable: false },
        { text: "Nomor Telepon Penanggung Jawab", sortable: false },
        { text: "Email Penanggung Jawab", sortable: false },
        { text: "Tanggal Daftar", sortable: false }
      ],
      registrationApprove: false,
      registrationReject: false,
      villageSelect: "",
      villageList: [],
      flagReject: false,
      id: "",
      reasonMessage: "",
      supplierCode: "",
      supplierCodeRules: [
        v => !!v || "Kode Supplier wajib diisi",
        v => /^[a-z0-9]{6}$/i.test(v) || "Kode Supplier tidak valid"
      ]
    };
  },

  mounted() {
    this.getUser();
  },

  methods: {
    getUser() {
      RegistrationService.getRegistrationSupplier({
        menu_name: "Request Register Supplier"
      })
        .then(response => {
          this.supplierList = response.data.data;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        });
    },
    getDetail(id) {
      this.$root.$loaderModal.start("Loading...");
      this.flagReject = false;

      const payload = {
        menu_name: "Request Register Supplier",
        id: id
      };

      RegistrationService.getRegistrationSupplierDetail(payload)
        .then(response => {
          if (response) {
            this.dataDetail = response.data.data;
            this.id = id;
            this.dialog = true;
            this.getVillageList();
          }
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => this.$root.$loaderModal.hide());
    },
    getVillageList() {
      const payload = {
        postal_code: this.dataDetail.postal_code
      };

      RegistrationService.getRegistrationSupplierVillage(payload)
        .then(response => {
          this.villageList = response.data.villages;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        });
    },
    approve() {
      this.flagReject = false;
      this.registrationApprove = true;
      this.registrationReject = true;

      if (this.villageSelect === "" || this.supplierCode === "") {
        this.registrationApprove = false;
        this.registrationReject = false;

        return this.$tostini({
          message: "Data Wajib Diisi!",
          type: "error"
        });
      }

      const payload = {
        pending_task_id: this.id,
        supplier_code: this.supplierCode.toUpperCase(),
        postal_code_id: this.villageSelect
      };

      RegistrationService.approveRegistrationSupplier(payload)
        .then(response => {
          this.$tostini({
            message: response.data.message,
            type: "success"
          });
          this.dialog = false;
          this.getUser();
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.registrationApprove = false;
          this.registrationReject = false;
        });
    },
    reject() {
      this.flagReject = true;
      this.registrationReject = true;
      this.disabledApprove = true;

      if (!this.reasonMessage) {
        this.registrationReject = false;
        this.disabledApprove = false;

        return this.$tostini({
          message: "Alasan Wajib Diisi!",
          type: "error"
        });
      }

      const payload = {
        pending_task_id: this.id,
        reason_message: this.reasonMessage
      };

      RegistrationService.rejectRegistrationSupplier(payload)
        .then(response => {
          this.$tostini({
            message: response.data.message,
            type: "success"
          });

          this.dialog = false;
          this.getUser();
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.registrationReject = false;
          this.disabledApprove = false;
        });
    }
  }
};
</script>
